export default {
  getSectionContentFromState(state) {
    if (state.tulpa_page_template) {
      const _sectionContent = {};
      const _tulpaSections = state.tulpa_page_template.tulpa_sections;
      for (const contentKey in state.tulpa_page_template.content) {
        const contentItem =
          state.tulpa_page_template.content[contentKey];
        const _tulpaSection = _tulpaSections.find((e) => {
          return e.id == contentItem.id;
        });
        const _defaultContent = {};
        for (let key in _tulpaSection.content) {
          const _tulpaContentItem = contentItem.content[key];
          const _sectionContentItem = _tulpaSection.content[key];
          if (_tulpaContentItem == null || _tulpaContentItem.length === 0) {
            _defaultContent[key] = _sectionContentItem;
          } else {
            _defaultContent[key] = _tulpaContentItem;
          }
        }
        _sectionContent[contentKey] = {
          id: contentItem.id,
          content: state.content[contentKey]
            ? state.content[contentKey].content
            : {},
          name: _tulpaSection.name,
          fields: _tulpaSection.fields,
          defaultContent: _defaultContent,
        };
      }
      return _sectionContent;
    } else {
      return state.content;
    }
  }
}