import axios from 'axios'
export default {
  async create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('tulpa_page', data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async patch(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`tulpa_page/${id}`, data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}