<template>
  <div>
    <WsCreate
      ref="WsCreate"
      :modelName="model.tulpa_page.modelName"
      :label="model.tulpa_page.label"
      :fields="model.tulpa_page.fields"
      :primary="pageSetting.primary"
      :secondary="_pageSettingSecondary"
      v-model="state"
      :emitSubmit="true"
      @submit="$_onSubmit()"
      :emitInput="true"
    >
    </WsCreate>

    <WsSectionPrimarySecondary>
      <template v-slot:primary>
        <WsMain class="pb-60">
          <WsStateTulpaSections
            :amountEditable="_amountEditable"
            :value="_sectionContent"
            @input="$_onTulpaSectionsInput($event)"
          ></WsStateTulpaSections>
        </WsMain>
      </template>
      <template v-slot:secondary>
      </template>
    </WsSectionPrimarySecondary>
  </div>
</template>

<script>
import tulpa_page from "@/__vue2stone_cms/models/tulpa_page";
import ServiceTulpaPage from "@/__vue2stone_cms/service/api/v1/tulpa_page";
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
import S_App_State from "@/__vue2stone_cms/service/app/state";
import S_App_Tulpa from "@/__vue2stone_cms/service/app/tulpa";
export default {
  metaInfo() {
    return {
      title: `${this.$t("new")} ${this.$t("page_section")}`,
    };
  },

  computed: {
    _pageSettingSecondary() {
      const fields = [
        "is_active",
        "name",
        "tulpa_page_template",
        "tulpa_cross_items",
        "remark",
      ];
      if (S_App_Auth.hasScope(["boss"])) {
        if (this.$config.wsmodule.country_code) {
          fields.push("country_code");
        }
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            fields.push("cmser_groups");
          } else {
            fields.push("admin_groups");
          }
        }
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              title: "頁面設定",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
    _amountEditable() {
      if (this.state.tulpa_page_template) {
        return false;
      } else {
        return true;
      }
    },
    _sectionContent() {
      return S_App_Tulpa.getSectionContentFromState(this.state);
    },
  },

  data: () => ({
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "頁面SEO設定",
              titleInLocale: true,
              fields: [
                "route",
                "title",
                "og_image",
                "canonical_url",
                "description",
                "tags",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "setting",
              titleInLocale: true,
              fields: [
                "tulpa_page_template",
                "tulpa_cross_items",
                "is_active",
                "remark",
              ],
            },
          ],
        },
      ],
    },
    model: {
      tulpa_page: tulpa_page,
    },
    state: {
      content: [],
    },
  }),

  methods: {
    $_onTulpaSectionsInput($event) {
      if (this.state.tulpa_page_template) {
        this.state.content = $event;
      } else {
        this.state.content = $event;
      }
    },
    async $_onSubmit() {
      const postData = S_App_State.getFormatedStates(
        this.model.tulpa_page.fields,
        this.state,
        true
      );
      const _content = {};
      const _tulpa_sections = [];
      for (const key in this.state.content) {
        const contentItem = this.state.content[key];
        _content[key] = {
          id: contentItem.id,
          content: contentItem.content,
        };
        _tulpa_sections.push(contentItem.id);
      }
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(_content)))
      );
      if (!this.state.tulpa_page_template) {
        postData.tulpa_sections = _tulpa_sections;
      }
      try {
        this.$refs.WsCreate.loadingStart();
        await ServiceTulpaPage.create(postData);
        this.$router.push("/tulpa_page");
      } catch (error) {
        console.error(error);
        alert(this.$t("make_sure_route_not_repeat"));
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
  },

  watch: {
    "state.tulpa_page_template": {
      handler() {
        this.state.content = [];
      },
    },
  },
};
</script>

<style>
</style>